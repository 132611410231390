var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content" }, [
    _c(
      "div",
      { staticClass: "content", staticStyle: { position: "relative" } },
      [
        _c("head-layout", {
          attrs: {
            "head-btn-options": _vm.headBtnTopOptions,
            "head-title": "风险信息",
          },
          on: { "head-cancel": _vm.headCancel },
        }),
        _c(
          "el-container",
          [
            _c("CommonTree", {
              staticClass: "riskClass",
              attrs: {
                treeTitle: "风险源",
                defaultProps: _vm.defaultProps,
                isShowdig: false,
                searchTitle: _vm.searchTitle,
                showCheckbox: false,
                treeData: _vm.treeData,
                treeExpand: true,
                risk: true,
                "node-key": "id",
                defaultCheckedKeys: _vm.defaultCheckedKeys,
                defaultExpandedKeys: _vm.defaultExpandedKeys,
              },
              on: { getNodeClick: _vm.handleNodeClick },
            }),
            _c(
              "el-main",
              { staticStyle: { position: "relative" } },
              [
                _c("head-layout", {
                  attrs: {
                    "head-btn-options": _vm.headBtnOptions,
                    "head-title": "风险清单",
                  },
                  on: {
                    "head-add": function ($event) {
                      return _vm.opDialog()
                    },
                    "head-delete": _vm.deletesList,
                  },
                }),
                _c("grid-head-layout", {
                  ref: "searchForm",
                  attrs: {
                    searchSpan: "4",
                    "search-columns": _vm.searchColumns,
                  },
                  on: {
                    "grid-head-search": _vm.handleSearch,
                    "grid-head-empty": _vm.searchReset,
                  },
                }),
                _c("grid-layout", {
                  ref: "gridLayout",
                  attrs: {
                    "data-total": _vm.tableTotal,
                    page: _vm.page,
                    tableData: _vm.tableData,
                    tableLoading: _vm.tableLoading,
                    tableOptions: _vm.tableOptions,
                  },
                  on: {
                    "page-size-change": _vm.handleSizeChange,
                    "page-current-change": _vm.handleCurrentChange,
                    "page-refresh-change": _vm.onLoad,
                    "gird-handle-select-click": _vm.selectionChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "riskLevel",
                      fn: function ({ row }) {
                        return [
                          row.lecdD
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "center",
                                    "align-item": "center",
                                    padding: "4px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        border: `1px solid ${row.lecdColor}`,
                                        borderRadius: "4px",
                                        color: row.lecdColor,
                                        backgroundColor: _vm.computedDlevel(
                                          row.lecdD
                                        ).bg,
                                        textAlign: "center",
                                        display: "inline-block",
                                        padding: "0 20px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(row.lecdD) +
                                          " 级\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "lecdDScore",
                      fn: function ({ row }) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(row.lecdDScore == -1 ? "" : row.lecdDScore)
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "riskCode",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.linkDetail(row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.riskCode) + "\n            ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }