<template>
  <div class="main-content">
    <div style="position: relative" class="content">
      <head-layout
        @head-cancel="headCancel"
        :head-btn-options="headBtnTopOptions"
        head-title="风险信息"
      >
      </head-layout>
      <el-container>
        <CommonTree
          treeTitle="风险源"
          class="riskClass"
          :defaultProps="defaultProps"
          :isShowdig="false"
          :searchTitle="searchTitle"
          :showCheckbox="false"
          :treeData="treeData"
          :treeExpand="true"
          :risk="true"
          node-key="id"
          :defaultCheckedKeys="defaultCheckedKeys"
          :defaultExpandedKeys="defaultExpandedKeys"
          @getNodeClick="handleNodeClick"
        />
        <el-main style="position: relative">
          <head-layout
            :head-btn-options="headBtnOptions"
            head-title="风险清单"
            @head-add="opDialog()"
            @head-delete="deletesList"
          >
          </head-layout>
          <grid-head-layout
            ref="searchForm"
            :searchSpan="'4'"
            :search-columns="searchColumns"
            @grid-head-search="handleSearch"
            @grid-head-empty="searchReset"
          />
          <grid-layout
            ref="gridLayout"
            :data-total="tableTotal"
            :page="page"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :tableOptions="tableOptions"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            @gird-handle-select-click="selectionChange"
          >
          <template slot="riskLevel" slot-scope="{ row }">
              <div
                v-if="row.lecdD"
                style="
                  display: flex;
                  justify-content: center;
                  align-item: center;
                  padding: 4px;
                "
              >
                <div
                  :style="{
                    border: `1px solid ${row.lecdColor}`,
                    borderRadius: '4px',
                    color: row.lecdColor,
                    backgroundColor: computedDlevel(row.lecdD).bg,
                    textAlign: 'center',
                    display: 'inline-block',
                    padding: '0 20px',
                  }"
                >
                  {{ row.lecdD }} 级
                </div>
              </div>
            </template>
            <template slot="lecdDScore" slot-scope="{ row }">
              <div>{{ row.lecdDScore == -1 ? "" : row.lecdDScore }}</div>
            </template>
            <template slot="riskCode" slot-scope="{ row }">
              <el-link type="primary" @click="linkDetail(row)"
              >{{ row.riskCode }}
              </el-link>
            </template>
            <!-- <template #customBtn="{ row, index }">
              <el-button
                type="text"
                size="small"
                @click="linkDetail(row, index)"
              >
                查看
              </el-button>
            </template> -->
          </grid-layout>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonTree from "@/views/components/com_tree/index";
import {
  riskPublishTypeTree,
  riskPublishListPage,
} from "@/api/riskManage/project";
import {getbsrisklecddlevelMap} from "@/api/setting/riskAssessment/index";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
export default {
  components: {
    GridLayout,
    HeadLayout,
    CommonTree,
  },
  data() {
    return {
      treeData: [],
      searchTitle: "title",
      defaultProps: {
        children: "children",
        id: "id",
        label: "title",
      },
      defaultCheckedKeys: [],
      
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      mainId: "",
      organizationId: "",
      searchColumns: [
        // {
        //   placeholder: this.$t("cip.cmn.rule.inputWarning") + "风险编号",
        //   prop: "riskCode",
        //   span: 4,
        // },
        // {
        //   placeholder: this.$t("cip.cmn.rule.inputWarning") + "风险描述",
        //   prop: "riskName",
        //   span: 4,
        // },
        // {
        //   placeholder: this.$t("cip.cmn.rule.inputWarning") + "风险源",
        //   prop: "sourceName",
        //   span: 4,
        // },
        // {
        //   placeholder: this.$t("cip.cmn.rule.selectWarning") + "风险等级",
        //   type: "select",
        //   prop: "riskLevel",
        //   span: 4,
        //   dicData: [],
        //   props: {
        //     label: "levelName",
        //     value: "levelName",
        //   },
        //   dataType: "number",
        // },
      ],
      tableTotal: 0,
      tableLoading: false,
      tableData: [],
      tableOptions: {
        menuFixed: "right",
        customRowAddBtn: false,
        menu: false,
        menuWidth: 200,
        selection: false,
        column: [
          {
            label: "风险编号",
            prop: "riskCode",
            align: "center",
            overHidden: true,
            minWidth: 100,
            slot: true,
          },
          {
            label: "风险描述",
            prop: "riskName",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          //   {
          //     label: "发布状态",
          //     prop: "publishStatus",
          //     align: "center",
          //     dicUrl:
          //       "/api/sinoma-system/dict-biz/dictionary?code=process_status",
          //     props: {
          //       label: "dictValue",
          //       value: "dictKey",
          //     },
          //     overHidden: true,
          //     minWidth: 100,
          //   },
          {
            label: "风险源",
            prop: "sourceName",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "事故类型",
            prop: "eventName",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "现有控制措施",
            children: [
              {
                label: "工程技术",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatEngineer",
              },
              {
                label: "管理措施",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatManagment",
              },
              {
                label: "培训教育",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatTraining",
              },
              {
                label: "个体防护",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatProtection",
              },
              {
                label: "应急处置",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatEmergency",
              },
            ],
          },
          {
            label: "风险评价",
            children: [
              {
                label: "L",
                prop: "lecdLScore",
                align: "center",
                overHidden: true,
              },
              {
                label: "E",
                prop: "lecdEScore",
                align: "center",
                overHidden: true,
              },
              {
                label: "C",
                prop: "lecdCScore",
                align: "center",
                overHidden: true,
              },
              {
                slot: true,
                label: "D",
                prop: "lecdDScore",
                align: "center",
                overHidden: true,
              },
            ],
          },
          {
            label: "风险等级",
            prop: "riskLevel",
            align: "center",
            overHidden: true,
            minWidth: 100,
            slot: true,
          },
          // {
          //   label: "状态",
          //   prop: "status",
          //   align: "center",
          //   dicUrl:
          //     "/api/sinoma-system/dict-biz/dictionary?code=is_training_plan_status",
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey",
          //   },
          //   dicData: [],
          //   overHidden: true,
          //   dataType: "number",
          // },
        ],
      },
      searchForm: {},
      treeNode: {},
      D: [],
      isHideTopBtn: false
    };
  },
  computed:{
    headBtnTopOptions(){
      return this.isHideTopBtn?[]:[{
          label: "取消",
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: "cancel",
        }]
    }
  },
  watch: {
    defaultCheckedKeys: {
      // immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document.querySelector(".riskClass .el-tree-node__content").click();
          });
        }
      },
    },
  },
  mounted() {
    if(this.$route.query.id){
      this.isHideTopBtn = true
    }else{
      this.isHideTopBtn = false
    }
    this.mainId = this.$route.query.id?this.$route.query.id:this.$route.query.mainId;
    this.organizationId = this.$route.query.organizationId;
    this.getTreeData(this.mainId);
    this.getbsrisklecddlevelMap();
  },
  methods: {
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    getTreeData(mainId, organizationId) {
      riskPublishTypeTree({ mainId }).then((res) => {
        if (res.status == 200) {
          this.treeData = res.data.data;
          this.$nextTick(() => {
            this.defaultCheckedKeys.push(this.treeData[0].id);
            this.defaultExpandedKeys = this.treeData.map((item) => {
              return item.id;
            });
          });
        }
      });
    },
    handleNodeClick(data) {
      this.treeNode = data;
      this.page.currentPage = 1;
      this.onload(this.page);
    },
    onload(page) {
      let params = {
        size: this.page.pageSize,
        current: this.page.currentPage,
        treeId: this.treeNode.id,
        mainId: this.mainId,
        ...this.searchForm,
      };
      riskPublishListPage(params)
        .then((res) => {
          this.tableData = res.data.data.records.map((item) => {
            return {
              ...item,
              ...item.pmRiskLibrary,
            };
          });
          console.log("---", this.tableData);
          this.tableTotal = res.data.data.total;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
    handleSearch(searchForm) {
      this.searchForm = searchForm;
      this.page.currentPage = 1;
      this.onload(this.page);
    },
    searchReset(searchForm) {
      this.searchForm = {};
      this.page.currentPage = 1;
      this.onload(this.page);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onload(this.page);
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onload(this.page);
    },
    // 获取D映射
    getbsrisklecddlevelMap() {
      //  createDept: this.userInfo.dept_id
      getbsrisklecddlevelMap({}).then((res) => {
        this.D = res.data.data;
        this.searchColumns[3].dicData = res.data.data;
      });
    },
    computedDlevel(levelCode) {
      let result = this.D.find((item) => item.levelCode == levelCode);
      if (result) {
        result.bg =
          result.color.substring(0, 3) +
          result.color.substring(3, result.color.length - 2) +
          "0.1)";
      }

      return (
        result || {
          color: "rgba(252, 233, 119, 1)",
          bg: "rgba(252, 233, 119, 0.1)",
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .top {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .canvas-box {
      border-radius: 4px;
      width: 50%;
      height: 100%;
      background: #fff;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;

      .title-box {
        width: calc(100% - 20px);
        position: absolute;
        z-index: 10;
        left: 10px;
        top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .bottom {
    border-radius: 4px;
    margin-top: 10px;
    background: #fff;
    display: flex;
    width: 100%;
    height: calc(50% - 10px);
    overflow: scroll;

    .el-container {
      height: 100%;

      .el-main {
        height: 100%;
        overflow: hidden;
      }
    }
  }
}

.el-main {
  overflow: hidden;
}
</style>
